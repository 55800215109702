body {
  margin: 0;
  padding: 0;
  padding-bottom: 60px;
}

.ranUI {
  line-height: 2;
  background-color: #aec42e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav a {
  font-size: 20px;
  text-decoration: none;
  margin: 10px 10px;
  padding: 13px 40px;
  background-color: #f0e494;
  border-radius: 8px;
  font-weight: bold;
  color: black;
}
.navbar-wrapper {
  border-radius: 15px 15px 0px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #1c3455;
  color: white;
  text-align: center;
}
.backToHomeBtn {
  background-color: #1c3455;
  font-weight: bold;
  border-radius: 6px;
  margin: 50px auto;
  cursor: pointer;
  padding: 13px 40px;
  top: 0;
  left: 0;
  text-align: center;
  width: 300px;
  color: #fff;
  text-decoration: none;
}
h1 {
  text-align: center;
}
nav a:visited {
  color: black;
}
.YesUI {
  display: inline-block;
  padding: 15px 45px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.YesUI:hover {
  background-color: #3e8e41;
}

.YesUI:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.NoUI {
  display: inline-block;
  padding: 15px 45px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #af4c4c;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.NoUI:hover {
  background-color: #3e8e41;
}

.NoUI:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

div.card {
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  width: 100%;
  margin-bottom: 60px;
}

div.header {
  background-color: #dddddd;
  border-radius: 15px 15px 15px 15px;
  padding: 5px;
  font-size: 25px;
  text-align: center;
  width: 100%;
}

div.header2 {
  background-color: rgb(50, 235, 62);
  border-radius: 15px 15px 15px 15px;
  color: rgb(0, 0, 0);
  padding: 10px;
  font-size: 25px;
  text-align: center;
  width: 100%;
}

div.header3 {
  background-color: #dddddd;
  border-radius: 15px 15px 15px 15px;
  padding: 15px;
  font-size: 25px;
  text-align: center;
  width: 100%;
}

div.container {
  padding: 20px;
  text-align: center;
}

div.container2 {
  background-color: #dddddd;
  text-align: center;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

div.container3 {
  background-color: #e6e6e6;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
}

.rcorners1 {
  border-radius: 25px;
  border: 2px solid #8ac007;
  padding: 20px;
  width: 200px;
  height: 150px;
  margin: auto;
  display: inline-block;
}

.rcorners2 {
  border-radius: 25px;
  border: 2px solid #8ac007;
  padding: 20px;
  width: 200px;
  height: 150px;
  margin: auto;
}

.CUI {
  display: inline-block;
  padding: 5px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #be8627;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  
}

.CUI:hover {
  background-color: #3e8e41;
}

.CUI:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.AUI {
  background-color: #1c3455;
  padding: 0px;
}

.container2:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  background-color: #1c3455;
  position: fixed;
  bottom: 0;
}
.Login {
  position: absolute;
  top: 0;
  right: 0;
}

.Loginpopup {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.visibleArea {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  padding: 30px 150px;
  background-color: #dddddd;
  z-index: 1;
  border-radius: 15px 15px 15px 15px;
}

.large-modal {
  max-width: 300%;
  max-height: 300%;
}
